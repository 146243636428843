import React from "react";
import theme from "theme";
import { Theme, Image, Link, Section, Box, Strong, Text, Icon, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
import { FaUniversity, FaHornbill, FaReact, FaAndroid, FaCodepen, FaSistrix } from "react-icons/fa";
import { AiFillLinkedin } from "react-icons/ai";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"aisc"} />
		<Helmet>
			<title>
				AISC
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"AISC"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI%20Favicon.png?v=2024-02-10T08:45:31.376Z"} type={"image/x-icon"} />
		</Helmet>
		<Section
			quarkly-title="Header"
			align-items="center"
			justify-content="center"
			padding="16px 0 16px 0"
			color="#872929"
			background="--color-light"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Link position="relative" transition="opacity 200ms ease" quarkly-title="Link" href="/">
				<Image
					z-index="3"
					src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14:58:12.720Z"
					width="120px"
					srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Link>
			<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end" margin="35px 0px 0px 0px">
				<Override
					slot="icon"
					icon={MdMenu}
					size="36px"
					md-right="0px"
					md-position="relative"
					category="md"
				/>
				<Override
					slot="menu-open"
					md-justify-content="center"
					md-top={0}
					md-bottom={0}
					md-display="flex"
					md-flex-direction="column"
					md-align-items="center"
				/>
				<Override slot="icon-open" md-right="18px" md-position="fixed" md-top="18px" />
				<Override
					slot="menu"
					md-height="100%"
					justify-content="center"
					lg-transition="transform 400ms ease 0s"
					md-position="fixed"
					display="flex"
					md-top={0}
					md-width="100%"
					lg-transform="translateY(0px) translateX(0px)"
					md-left={0}
					padding="0px 0 0px 0"
					align-items="center"
					filterPages="/aisc-davis"
				>
					<Override slot="item-index" lg-display="none" display="none" />
					<Override
						slot="link"
						hover-opacity="1"
						md-transition="opacity .15s ease 0s"
						md-font="16px/24px sans-serif"
						font="--base"
						color="--dark"
						opacity=".5"
						transition="opacity .15s ease 0s"
						text-transform="initial"
						md-color="--dark"
						letter-spacing="0.5px"
						md-opacity=".5"
						md-hover-opacity="1"
						md-active-opacity="1"
						text-decoration-line="initial"
					/>
					<Override
						slot="link-active"
						opacity="1"
						color="--primary"
						cursor="default"
						md-opacity="1"
						md-cursor="default"
					/>
					<Override
						slot="item"
						text-align="center"
						padding="8px 20px 8px 20px"
						md-padding="16px 40px 16px 40px"
						display="inline-block"
						text-transform="uppercase"
					/>
					<Override slot="item-404" lg-display="none" display="none" />
					<Override slot="link-work">
						AI Student Collective
					</Override>
				</Override>
				<Override slot="icon,icon-close" category="md" icon={MdMenu} />
			</Components.BurgerMenu>
		</Section>
		<Box min-width="100px" min-height="100px" text-align="center" background="--color-light">
			<Image
				src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aiscDark.png?v=2023-09-07T03:33:26.896Z"
				display="inline-block"
				width="30%"
				position="relative"
				srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aiscDark.png?v=2023-09-07T03%3A33%3A26.896Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aiscDark.png?v=2023-09-07T03%3A33%3A26.896Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aiscDark.png?v=2023-09-07T03%3A33%3A26.896Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aiscDark.png?v=2023-09-07T03%3A33%3A26.896Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aiscDark.png?v=2023-09-07T03%3A33%3A26.896Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aiscDark.png?v=2023-09-07T03%3A33%3A26.896Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aiscDark.png?v=2023-09-07T03%3A33%3A26.896Z&quality=85&w=3200 3200w"
				sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
			/>
		</Box>
		<Section
			border-color="--color-lightD2"
			border-style="solid"
			sm-padding="24px 0 24px 0"
			quarkly-title="About"
			box-sizing="border-box"
			background="--color-light"
			padding="50px 0px 100px 0px"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
			position="static"
			border-width="0px"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				margin="0px 32px 0px 32px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Text
				text-align="left"
				width="85%"
				lg-font="--lead"
				sm-font="--base"
				font="--headline3"
				margin="0px 0px 0px 0px"
				letter-spacing="1px"
				color="--dark"
			>
				The{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					AI Student Collective (AISC)
				</Strong>
				{" "}is a global network of student organizations dedicated to providing accessible AI literacy through pre-professional programs and events. We work to create a more{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					informed future in AI
				</Strong>
				{" "}by leveraging industry connections to elevate student experiences and provide exciting professional development opportunities. Each year will be marked by{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					high-impact events
				</Strong>
				, such as a symposium and product competition, as well as local opportunities such as resume workshops, code-alongs, and corporate mixers. By building a community of{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					responsible and insightful students
				</Strong>
				{" "}across the country, we hope to do our part in contributing towards a more diverse and equitable future.
			</Text>
		</Section>
		<Section padding="30px 0 80px 0" background="--color-light" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Meet the Current AISC Branches
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
					max-width="480px"
				>
					Learn more about the branches focused on supporting a diverse AI ecosystem and being{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						B.O.L.D.
					</Strong>
					{" "}
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaUniversity}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						University of California, Davis
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Founded: September 2022
					</Text>
					<Link
						text-align="center"
						margin="18px 0px 0px 0px"
						hover-transform="translateY(-4px)"
						href="/aisc-davis"
						color="--light"
						padding="8px 18px 8px 18px"
						font="--base"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						text-decoration-line="initial"
						letter-spacing="0.5px"
						background="--color-primary"
						lg-margin="24px 0px 0px 0px"
						max-width="100px"
					>
						Learn More
					</Link>
				</Box>
				<Box
					padding="45px 45px 45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaHornbill}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						University of California, San Diego
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Founded: January 2023
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaReact}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						University of Texas, Dallas
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Founded: June 2023
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-light" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaAndroid}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						University of California, Riverside
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Founded: August 2023
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaCodepen}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						University of Washington
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Founded: August 2024
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaSistrix}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						More Coming Soon!
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						<br />
						{" "}
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Footer"
			justify-content="center"
			background="--color-light"
		>
			<Override
				slot="SectionContent"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				margin="0px 32px 0px 32px"
			/>
			<Box
				grid-gap="32px"
				width="100%"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-gap="16px"
				display="grid"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Image
						width="50px"
						height="50px"
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21:53:55.077Z"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Link
						white-space="nowrap"
						font="--base"
						opacity="0.6"
						text-align="left"
						margin="1px 0px 0px 10px"
						color="--dark"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
						href="/index#"
					>
						Humans for AI
					</Link>
				</Box>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap" justify-content="flex-end">
					<LinkBox href="https://www.linkedin.com/company/humansforai/" target="_blank">
						<Icon
							category="ai"
							icon={AiFillLinkedin}
							size="64px"
							padding="0px 3% 0px 3%"
							width="50px"
							height="50px"
							pointer-events="auto"
						/>
					</LinkBox>
					<LinkBox href="https://medium.com/@humansforai" target="_blank">
						<Image src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/icons8-medium-50.svg?v=2023-12-18T23:16:50.399Z" display="block" />
					</LinkBox>
				</Box>
			</Box>
		</Section>
	</Theme>;
});